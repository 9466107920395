import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container } from './styles';
import CurrentPlan from './CurrentPlan';
import PurchasePlans from './PurchasePlans';
import { useConnect } from './connect';
import {
  MainContainer,
  MainContainerHeader,
  MainContainerTitle,
  MainContainerLoader,
} from '../../../../components/MainContainer';
import CheckoutResultAlerts from './CheckoutResultAlerts';
import { NotificationMessages } from '../../../../components/NotificationMessages';

const Billing = () => {
  const {
    workspaceDetails,
    messagesHandler,
    purchasePlansConnect,
    currentPlanConnect,
    isFree,
    wrongPlanRequested,
    queryParams,
  } = useConnect();

  if (wrongPlanRequested) {
    return (
      <div>
        <Redirect to={`/wrong-plan?${queryParams}`} />
      </div>
    );
  }

  if (
    purchasePlansConnect.isLoadingSubscription ||
    purchasePlansConnect.isLoadingPrices ||
    currentPlanConnect.isLoading
  ) {
    return <MainContainerLoader />;
  }

  return (
    <MainContainer title="Billing">
      <NotificationMessages handler={messagesHandler} />
      <MainContainerHeader direction="vertical">
        <MainContainerTitle append={workspaceDetails?.name}>
          Billing
        </MainContainerTitle>
      </MainContainerHeader>
      <Container>
        <CheckoutResultAlerts />
        <CurrentPlan {...currentPlanConnect} />
        {isFree && (
          <>
            <span />
            <PurchasePlans {...purchasePlansConnect} />
          </>
        )}
      </Container>
    </MainContainer>
  );
};

export default Billing;
