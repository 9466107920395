import React, { FC, memo, useMemo } from 'react';
import { Tooltip } from '@scriptaddicts/yamm-ui-components';
import {
  Container,
  PricingCardTop,
  PricingCardBottom,
  PriceTitle,
  PriceTargetText,
  SubscribeLink,
  CardText,
  InfoIcon,
} from './styles';
import Price from './price';
import { Props } from './types';

const PriceCard: FC<Props> = ({ data, onTooltipClick, ...props }) => {
  const formattedRecipients = useMemo(
    () => new Intl.NumberFormat('en').format(data.recipients),
    [data.recipients],
  );

  return (
    <Container {...props}>
      {/* <TooltipStyles /> */}
      <PricingCardTop>
        <PriceTitle>{data.title}</PriceTitle>
        <PriceTargetText>{data.target}</PriceTargetText>
        <Price
          price={data.price}
          currency={data.currency}
          billingPeriod={data.billingPeriod}
        />
      </PricingCardTop>

      <PricingCardBottom>
        <CardText>
          <b>All</b>&nbsp;features included
        </CardText>
        <CardText>
          <b>No</b>&nbsp;branded footer
        </CardText>
        <CardText>
          <b>Up to {formattedRecipients}</b>&nbsp;recipients / day
          {data.tooltipContent && (
            <Tooltip tip={data.tooltipContent}>
              <InfoIcon onClick={() => onTooltipClick && onTooltipClick()} />
            </Tooltip>
          )}
        </CardText>
        <CardText>
          <b>1</b>&nbsp;user
        </CardText>
        <CardText>
          <b>Priority</b>&nbsp;support
        </CardText>
        <SubscribeLink to={data.subscribeHref}>Subscribe</SubscribeLink>
      </PricingCardBottom>
    </Container>
  );
};

export default memo(PriceCard);
