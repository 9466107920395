import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../../utils/useQueryParams';

export const useQuotaConditionsModalTrigger = () => {
  const history = useHistory();
  const params = useQueryParams();
  const displayed = params.has('quota-conditions');

  const trigger = useCallback(() => {
    const currentLocation = new URL(window.location.href);
    if (displayed) {
      history.goBack();
    } else {
      currentLocation.searchParams.set('quota-conditions', '1');
      history.push(currentLocation.pathname + currentLocation.search);
    }
  }, [history, displayed]);

  return {
    isOpen: displayed,
    trigger,
  };
};
