import React, { useEffect, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useWorkspaceContext } from '../../../data/context/WorkspaceContext';
import { MainContainerLoader } from '../../../components/MainContainer';

const BillingRedirection = () => {
  const { id, otherWorkspaces, setSelectedWorkspace } = useWorkspaceContext();

  const location = useLocation();
  const queryParams = location.search;

  const isBillingPageRequested = useMemo(() => {
    const currentPath = location.pathname;
    return currentPath === `/settings/billing`;
  }, [location.pathname]);

  const ownedWorkspace = useMemo(() => {
    return otherWorkspaces.find((workspace) => workspace.role === 'OWNER');
  }, [otherWorkspaces]);

  useEffect(() => {
    if (ownedWorkspace && id && ownedWorkspace.id !== id) {
      setSelectedWorkspace(ownedWorkspace.id);
    }
  }, [ownedWorkspace, id]);

  if (ownedWorkspace && isBillingPageRequested && ownedWorkspace.id === id) {
    const to = `/${ownedWorkspace.urlPrefix}/space/billing${queryParams}`;
    return <Redirect to={to} />;
  }
  return <MainContainerLoader />;
};

export default BillingRedirection;
