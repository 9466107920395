import React from 'react';
import {
  Container,
  Content,
  Cards,
  TitleText,
  Text,
  SignInButton,
  BackLink,
  StyledPricingCard,
} from './styles';
import { useConnect } from './connect';
import { ProGmailPlan, ProGoogleWorkspacePlan } from './data';
import QuotaConditionsModal from './QuotaConditionsModal';

const WrongPlanChoice = () => {
  const {
    me,
    isGmail,
    requestedPlan,
    onSwitchAccount,
    onTooltipClick,
  } = useConnect();

  const gmailPlan = ProGmailPlan;
  const googleWorkspacePlan = ProGoogleWorkspacePlan;

  return (
    <Container>
      <Content>
        <TitleText>
          You are trying to purchase a {requestedPlan || ''} plan with{' '}
          <u>{me?.email}</u>
        </TitleText>
        <Text>
          {isGmail ? (
            <>
              Gmail.com addresses are only eligible to the {gmailPlan.title}{' '}
              plan:
            </>
          ) : (
            <>
              Google Workspace addresses are only eligible to the{' '}
              {googleWorkspacePlan.title} plan:
            </>
          )}
        </Text>
        <Cards>
          <StyledPricingCard
            onTooltipClick={onTooltipClick}
            data={isGmail ? gmailPlan : googleWorkspacePlan}
          />
        </Cards>
        <Text>
          {isGmail ? (
            <>
              Do you want a {googleWorkspacePlan.title} plan for your Google
              Workspace account?
            </>
          ) : (
            <>Do you want a {gmailPlan.title} plan for your Gmail account?</>
          )}
        </Text>
        <SignInButton onClick={onSwitchAccount}>
          Sign-in with another account
        </SignInButton>
        <BackLink
          href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing?spaces=true`}
        >
          Back to YAMM pricing plans
        </BackLink>
      </Content>
      <QuotaConditionsModal />
    </Container>
  );
};

export default WrongPlanChoice;
