import { useCallback } from 'react';
import { useAuth } from '../../data/context/AuthContext';
import useQueryParams from '../../utils/useQueryParams';
import { useMe } from '../../data/hooks/useMe';
import { isGmailAddress } from '../../utils/userUtils';
import { useQuotaConditionsModalTrigger } from './QuotaConditionsModal/useQuotaConditionsModalTrigger';

export const useConnect = () => {
  const { data: me } = useMe();
  const params = useQueryParams();

  const requestedPlanInQuery = params.get('plan');
  const planDict: { [key: string]: string } = {
    TEAM: 'TEAM',
    PERSONAL: 'PERSONAL',
    PROFESSIONAL: 'PROFESSIONAL',
    'pro-gmail': 'Pro gmail.com',
    'pro-google-workspace': 'Pro Google Workspace',
  };
  const requestedPlan = requestedPlanInQuery
    ? planDict[requestedPlanInQuery as keyof typeof planDict]
    : null;

  const { onSwitchAccount } = useAuth();
  const { trigger } = useQuotaConditionsModalTrigger();

  const isGmail = me?.email ? isGmailAddress(me.email) : false;
  const onTooltipClick = useCallback(() => {
    if (!isGmail) {
      trigger();
    } else {
      window.open(
        'https://support.yet-another-mail-merge.com/hc/en-us/articles/210730829#Paid-version',
        '_blank',
        'noopener,noreferrer',
      );
    }
  }, [isGmail]);

  return {
    me,
    isGmail,
    requestedPlan,
    onSwitchAccount,
    onTooltipClick,
  };
};
